import { fetchAuthSession } from "aws-amplify/auth";
import { CaregiverApi } from "../openapi";
import { getServiceConfig } from "./helper";

let caregiverApi: CaregiverApi;

export interface AppTimeLimit {
  packageName: string;
  minutes?: number;
}

export interface NetworkInfo {
  sim1?: Sim;
}

export interface Sim {
  mIccId?: string;
}

export interface DeviceData {
  lastReportedTime: string;
  realTimeStats: {
    wifiSSID: string;
    batteryLevel: number;
    sdCardSize: number;
    sdCardUsed: number;
  };
  hardwareInfo: {
    manufacturer: string;
    model: string;
  };
  imeiNumber: string;
  deviceId: string;
  appLimits?: {
    appUsage?: {
      Monday: [AppTimeLimit];
      Tuesday: [AppTimeLimit];
      Wednesday: [AppTimeLimit];
      Thursday: [AppTimeLimit];
      Friday: [AppTimeLimit];
      Saturday: [AppTimeLimit];
      Sunday: [AppTimeLimit];
    };
  };
  networkInfo?: NetworkInfo;
}

const getDeviceDataById = async (deviceId: string) => {
  const session = await fetchAuthSession();
  const accessToken = session?.tokens?.accessToken.toString();
  const response = await fetch(`${import.meta.env.VITE_FIELDX_API_URL}?deviceId=${deviceId}`, {
    method: "GET",
    headers: {
      authorization: accessToken ?? "",
    },
  });
  return response;
};

const getCaregiverService = async () => {
  if (!caregiverApi) caregiverApi = new CaregiverApi(await getServiceConfig());
  return caregiverApi;
};

export { getDeviceDataById, getCaregiverService };
